.d-f{
  display: flex;
}
.a-i-c{
  align-items: center;
}
.j-c-e{
  justify-content: flex-end;
}
.cols{
  display: flex;
}

.col{
  flex: 1 0 auto;
}
