.btn{
  border: none;
  font-size: 14px;
  padding: 10px 18px;
}

.btn-d{
  color: white;
  background-color: #EE2239;
}
.hr{
  color: whitesmoke;
}
.mt-1{
  margin-top: 1em;
}
